var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("page-header", {
        attrs: {
          light: "",
          "dark-crumb": "",
          title: "Пользовательское соглашение и политика конфиденциальности",
        },
      }),
      _c("content-wrapper-block", [
        _c("div", { staticClass: "agreement" }, [
          _c("div", { staticClass: "agreement-text" }, [
            _c("p", [
              _vm._v(" 1. Пользовательское соглашение"),
              _c("br"),
              _vm._v(
                " 1.1. В настоящем документе и вытекающих или связанных с ним отношениях Сторон применяются следующие термины и определения: "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Соглашение — настоящий документ со всеми дополнениями, изменениями и указанными в нем обязательными документами, а также договор, заключенный на его основании. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Пользователь — дееспособное физическое лицо старше 18 лет, присоединившееся к настоящему "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Соглашению в собственном интересе либо выступающее от имени и в интересах представляемого им юридического лица. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Сайт / Сайты — автоматизированная информационная система, доступная в сети интернет по доменному имени из сетевого адреса Соглашения, а также по поддоменам. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Приложение — программы для ЭВМ и/или базы данных, в том числе Сайт, предназначенные для предоставления доступа к Сервису с использованием Устройства в информационных целях. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Устройство — персональный компьютер, планшет, мобильный телефон, коммуникатор, смартфон, иное устройство, позволяющее использовать Приложение и/или Сервис по их функциональному назначению. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Сервис — комплекс услуг, предоставляемых Пользователю с использованием Приложения. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Контент — изображения, текстовые, аудио- и видеоматериалы, а также прочие объекты авторских и (или) смежных прав, а равно не являющиеся таковыми информация и сообщения любого характера доступные Пользователю с помощью Приложения. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Личный кабинет — персональный раздел Приложения, к которому Пользователь получает доступ после прохождения регистрации и/или авторизации в Приложении. Личный кабинет предназначен для хранения персональных данных Пользователя, создания профиля Пользователя, а также просмотра и управления иными доступными функциональными возможностями Приложения и соответствующими условиями использования Приложения. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Администратор Приложения – юридическое лицо, которое предоставляет право пользования Приложением, Сервисом, Контентом. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " В настоящем Соглашении могут быть использованы иные термины и определения, не указанные в п.1.1. Соглашения. В этом случае толкование такого термина производится в соответствии с текстом Соглашения. В случае отсутствия однозначного толкования термина или определения в тексте Соглашения и иных документов, образующих договор на условиях Соглашения, следует руководствоваться его толкованием, определенным: в первую очередь — законодательством Российской Федерации, и в последующем — обычаями делового оборота и научной доктриной. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 1.2. Настоящее пользовательское Соглашение регулирует порядок работы настоящего Приложения, определяет условия использования Сервиса и Контента. Администратор Приложения указывает информацию о себе, а также, контактные данные для обратной связи на странице с Соглашением. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Настоящее Соглашение является публичной офертой в соответствии с действующим законодательством Российской Федерации. Совершение конклюдентных действий физическими, либо юридическими лицами (далее — «Пользователь»), направленных на использование Приложения считается безусловным принятием (акцептом) данного Соглашения. Настоящим Пользователь подтверждает, что акцепт Соглашения равносилен подписанию и заключению Соглашения на условиях, изложенных в настоящем Соглашении. "
              ),
            ]),
            _c("p", [
              _vm._v(
                "2. Условия использования сайта и политика конфиденциальности"
              ),
            ]),
            _c("p", [
              _vm._v(
                " 2.1. Использование любых материалов и сервисов Приложения регулируется нормами действующего законодательства Российской Федерации. Материалы и сервисы Приложения предназначены исключительно для использования в законных целях. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 2.2. Пользователь вправе по своему усмотрению знакомиться с Контентом Приложения и пользоваться Сервисом. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 2.3. Пользователь соглашается использовать Приложение, не нарушая имущественных и/или неимущественных прав третьих лиц, а равно запретов и ограничений, установленных применимым правом, включая без ограничения: авторские и смежные права, права на товарные знаки, знаки обслуживания и наименования мест происхождения товаров, права на промышленные образцы, права использовать изображения людей, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Приложения, в частности используя программы для вмешательства или попытки вмешательства в процесс нормального функционирования Приложения. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 2.4. Использование Контента без согласия правообладателя не допускается. Для правомерного использования Контента необходимо согласие Администратора сайта или правообладателя материалов. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 2.5. При наличии технической возможности Пользователь вправе оставлять комментарии и иные записи в Приложении. Пользователь гарантирует, что комментарии или иные записи не нарушают применимого законодательства, не содержат материалов незаконного, непристойного, клеветнического, дискредитирующего, угрожающего, порнографического, враждебного характера, а также содержащих домогательства и признаки расовой или этнической дискриминации, призывающих к совершению действий, которые могут быть квалифицированы как уголовные преступления, равно как и считаться недопустимыми по иным причинам, материалов, пропагандирующих культ насилия и жестокости, материалов, содержащих нецензурную брань. Администратор Приложения не обязуется проводить предварительную модерацию материалов и записей, размещаемых в Приложении Пользователями. За комментарии и другие материалы любого характера, оставленные Пользователем в Приложении, Пользователь несет ответственность самостоятельно и в полном объеме. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 2.6. Администратор Приложения не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки на которые могут содержаться в Приложении, Пользователь переходит по ссылкам, содержащимся в Приложении на внешние ресурсы, по своему усмотрению на свой страх и риск. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 2.7. Пользователь согласен с тем, что Администратор Приложения не несет ответственности перед Пользователем в связи с любыми возможными или возникшими потерями, или убытками, связанными с любым содержанием Приложения и работой Сервиса, товарами или услугами, доступными в Приложении или полученными через внешние сайты или ресурсы, либо иные контакты Пользователя, в которые он вступил, используя размещенную в Приложении информацию или ссылки на внешние ресурсы. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 2.8. Предоставляя в любой форме (заполнение форм в Приложении, регистрация в Приложении, осуществление заказов, подписка на рекламные рассылки и т.д.) свои персональные данные Администратору Приложения, в том числе, но не ограничиваясь, фамилия, имя, отчество; пол, возраст; дата и место рождения; паспортные данные; адрес регистрации по месту жительства и адрес фактического проживания; номер телефона (домашний, мобильный); данные документов об образовании, квалификации, профессиональной подготовке, сведения о повышении квалификации; семейное положение, сведения о составе семьи; отношение к воинской обязанности; сведения о трудовом стаже, предыдущих местах работы, доходах с предыдущих мест работы; СНИЛС; ИНН и другие персональные данные. Пользователь выражает согласие на обработку персональных данных Администратором Приложения в соответствии с Федеральным законом «О персональных данных» от 27.07.2006 № 152-ФЗ. Обработка персональных данных Пользователя регулируется действующим законодательством Российской Федерации и Политикой конфиденциальности Приложения. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3. Персональная информация и иные данные, предоставляемые Пользователем "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.1. Под персональной информацией (персональными данными) в настоящем Соглашении понимается: "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • информация, предоставляемая Пользователем самостоятельно, включая Фамилию, Имя, Отчество и другие данные; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • данные, которые передаются в автоматическом режиме в соответствии с настройками Устройства Пользователя в обезличенном виде. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.2. Администратор Приложения не осуществляет проверку достоверности предоставляемых Пользователем Персональных данных и наличия у Пользователя необходимого согласия на их обработку в соответствии с настоящей Политикой, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию таких данных в актуальном состоянии и получению всех необходимых согласий субъектов персональных данных. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 3.3. Персональная информация Пользователя обрабатывается Администратором Приложения в следующих целях: "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • идентификация стороны в рамках Сервисов, соглашений и договоров; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Приложения, исполнения соглашений и договоров, а также обработка запросов и заявок от Пользователя; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • улучшение качества Приложения, удобства его использования, разработка новых продуктов; "
              ),
            ]),
            _c("p", [_vm._v("• таргетирование рекламных материалов;")]),
            _c("p", [
              _vm._v(
                " • проведение статистических и иных исследований, на основе обезличенных данных. Обезличенные данные собираются Администратором Приложения самостоятельно, в том числе в части: ip адреса, браузера, операционной системы, времени доступа, адреса посещенных страниц Сайта с целью выявления и решения технических проблем, а также сбора обезличенных данных осуществляемого с помощью сервисов аналитики (Яндекс.Метрика, Google Analytics и т.д.); "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • для осуществления прав и законных интересов Администратора Приложения или третьих лиц; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • для достижения общественно значимых целей, в том числе для целей обеспечения достоверности информации, размещаемой Пользователем на Сайте, а также возможности идентификации Пользователей, размещавших такую информацию; "
              ),
            ]),
            _c("p", [
              _vm._v(
                " • для направления рекламы и (или) информации в рамках продвижения товаров, работ и услуг на рынке путем осуществления прямых контактов с Пользователем, в том числе с помощью средств связи и (или) по иным контактным данным в чем Пользователь настоящим выражает свое согласие. "
              ),
            ]),
            _c("p", [_vm._v("4. Требования к защите Персональной информации")]),
            _c("p", [
              _vm._v(
                " Администратор Приложения принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц. Администратор Приложения не несет ответственность за порядок использования Персональной информации Пользователя третьими лицами, с которыми Пользователь взаимодействует в рамках использования Приложения и/или Сервиса. "
              ),
            ]),
            _c("p", [_vm._v("5. Изменение и отзыв Персональной информации")]),
            _c("p", [
              _vm._v(
                " Согласие на обработку персональных данных может быть отозвано Пользователем в любой момент. Для реализации права на отзыв пользователь должен направить соответствующее обращение на адрес: 107023, г. Москва, ул. Большая Семеновская, д. 32, стр. 7. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 6. Администратор Приложения вправе в любое время в одностороннем порядке, без каких-либо уведомлений Пользователя, изменять Контент доступный в Приложении, а также изменять условия настоящего Соглашения. Такие изменения вступают в силу с момента размещения новой версии Соглашения на данной странице. Актуальная версия Соглашения всегда расположена на данной странице. Для избегания споров по поводу изменения Соглашения Администратор Приложения рекомендует периодически перечитывать Соглашение, расположенное на данной странице. При несогласии Пользователя с внесенными изменениями он обязан отказаться от использования Приложения. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 7. Администратор Приложения вправе в одностороннем порядке ограничивать доступ Пользователя к Приложению, если будет обоснованно считать, что Пользователь ведет неправомерную деятельность. Кроме того, Администратор Приложения вправе удалять материалы Пользователей по требованию уполномоченных органов государственной власти или заинтересованных лиц в случае, если данные материалы нарушают применимое законодательство или права третьих лиц. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 8. Администратор Приложения и Пользователь несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств в соответствии с настоящим Соглашением и действующим законодательством Российской Федерации. Администратор Приложения не несет ответственность за технические перебои в работе Приложения, вместе с тем Администратор Приложения обязуется принимать все разумные меры для предотвращения таких перебоев. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 9. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 10. Администратор вправе прекращать работу Приложения, а равно частично или полностью прекращать доступ к Приложению до завершения необходимого технического обслуживания и/или модернизации Приложения. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 11. Пользователь использует Приложение на свой собственный риск. Приложение предоставляется «как есть» на дату использования Приложения. Администратор не принимает на себя никакой ответственности, в том числе за соответствие Приложения целям Пользователя. Администратор вправе менять Приложение, Сервис, Контент в любой момент. Приложение, Сервис, Контент действительны на дату использования Пользователем. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 12. Администратор Приложения не гарантирует что: Приложение, Сервис, Контент соответствуют или будут соответствовать требованиям Пользователя; Приложение, Сервис, Контент будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с использованием Приложения, Сервисов, будут точными и надежными и могут использоваться для каких-либо целей или в каком-либо качестве (например, для установления и/или подтверждения каких-либо фактов); качество какого-либо продукта, услуги, информации и пр., полученных с использованием Приложения, Сервисов, будет соответствовать ожиданиям Пользователя. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 13. Любые информацию и/или материалы (в том числе загружаемое ПО, письма, какие-либо инструкции и руководства к действию и т.д.), доступ к которым Пользователь получает с использованием Приложения, Пользователь может использовать на свой собственный страх и риск и самостоятельно несет ответственность за возможные последствия использования указанных информации и/или материалов, в том числе за ущерб, который это может причинить компьютеру Пользователя или третьим лицам, за потерю данных или любой другой вред. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 14. Совершая действия по принятию настоящего Соглашения (оферты), Пользователь гарантирует, что ознакомлен, соглашается, полностью и безоговорочно принимает все условия Соглашения в целом, обязуется их соблюдать. "
              ),
            ]),
            _c("p", [
              _vm._v(" АО «ГК ОСНОВА»"),
              _c("br"),
              _vm._v(" ОГРН 1167746614530"),
              _c("br"),
              _vm._v(" ИНН 9715264590"),
              _c("br"),
              _vm._v(
                " 142718, Московская область, Район Ленинский, рабочий посёлок Бутово, территория жилой комплекс Бутово-Парк, д.18, корп.1, пом. 59 "
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }